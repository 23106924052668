import { Box, CardHeader } from "@mui/material";
import ColorTag from "../../../../../shared/components/ColorTag";
import { CashFlowCardHeaderProps } from "../../balance/mobile/types";
import { CardSubHeader } from "../../common/CardSubHeader";
import { cardHeaderPropsDefault } from "../../common/helpers";

const CashFlowCardHeader = ({
  title,
  subtitle,
  statusText,
  statusColor,
  titleVariant,
  bgColor,
}: CashFlowCardHeaderProps) => {
  return (
    <CardHeader
      sx={{ ...cardHeaderPropsDefault, p: 0 }}
      title={<CardSubHeader title={title} subtitle={subtitle} titleVariant={titleVariant} bgColor={bgColor} />}
      slotProps={{ title: { mr: 1 } }}
      action={
        <Box mr={1}>
          <ColorTag text={statusText} color={statusColor} />
        </Box>
      }
    />
  );
};

export default CashFlowCardHeader;
