import { Button, Stack } from "@mui/material";
import { parseISO } from "date-fns";
import { useCallback, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { numberComparerDescBy } from "../../../../../shared/utilities/arrayHelper";
import { api } from "../../../../api/client";
import ChangeRequestStatus from "./ChangeRequestStatus";
import EditAccountSettingsForm from "./EditAccountSettingsForm";
import {
  getInitialState,
  loadDataAction,
  mapFormStateToUpdateRequest,
  resetAction,
  setErrorsAction,
  validateForm,
} from "./accountSettingsEditState";

interface Props {
  canEdit: boolean;
  investorId: string;
}

const updateInvestorDetails = withErrorHandling(api.investorDetails.updateInvestorDetails);

const EditAccountSettings = ({ canEdit, investorId }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const [formState, setFormState] = useState(getInitialState());
  const [isSendingChangeRequest, setIsSendingChangeRequest] = useState(false);

  const getInvestorDetails = useCallback(() => api.investorDetails.getInvestorDetails(investorId), [investorId]);

  const getPendingChangeRequests = useCallback(
    () => api.changeRequests.getPendingChangeRequests(investorId, "InvestorDetailsChangeRequest"),
    [investorId]
  );

  const [investor, fetchInvestorError, { isFetching: isFetchingInvestor }] = useFetch(
    getInvestorDetails,
    (investor) => {
      setFormState(loadDataAction(investor));
    }
  );

  const [
    pendingChangeRequestsInfo,
    fetchChangeRequestsError,
    { setData: setPendingChangeRequestsInfo, isFetching: isFetchingChangeRequests },
  ] = useFetch(getPendingChangeRequests);

  if (investor === undefined || isFetchingInvestor || isFetchingChangeRequests) {
    return <InlineLoader />;
  }

  const handleSendRequest = async () => {
    const errors = validateForm(formState);
    setFormState(setErrorsAction(errors));
    if (Object.values(errors).some((error) => !!error)) {
      return;
    }

    setIsSendingChangeRequest(true);

    const [changeRequestDetails, error] = await updateInvestorDetails(
      investorId,
      mapFormStateToUpdateRequest(formState.values)
    );

    setIsSendingChangeRequest(false);

    if (error) {
      logError(error, "[AccountSettingsEditForm] handleSendRequest");
      sendNotificationError("Failed to send change request for update");
      return;
    }

    setPendingChangeRequestsInfo({ changeRequestDetails: [changeRequestDetails] });

    setFormState(resetAction());
  };

  const handleCancel = () => {
    setFormState(resetAction());
  };

  const handleChangeRequestCanceled = () => {
    setPendingChangeRequestsInfo({ changeRequestDetails: [] });
  };

  const latestPendingChangeRequest = pendingChangeRequestsInfo?.changeRequestDetails.sort(
    numberComparerDescBy((d) => parseISO(d.requestedAt).getDate())
  )[0];

  const allowFormEdit = canEdit && !isSendingChangeRequest && !latestPendingChangeRequest;

  return (
    <Stack spacing={3} width="100%" height="100%">
      {canEdit && (
        <ChangeRequestStatus
          investorId={investorId}
          investorName={investor.name || ""}
          fetchError={fetchInvestorError || fetchChangeRequestsError}
          pendingChangeRequest={latestPendingChangeRequest}
          onChangeRequestCanceled={handleChangeRequestCanceled}
        />
      )}

      <Stack spacing={4} width="100%">
        <EditAccountSettingsForm formState={formState} setFormState={setFormState} allowEdit={allowFormEdit} />

        {canEdit && (
          <Stack direction="row" spacing={1}>
            <Button
              loading={isSendingChangeRequest}
              variant="contained"
              disabled={!formState.isDirty || !allowFormEdit}
              onClick={handleSendRequest}
            >
              Send Request
            </Button>
            <Button
              variant="text"
              color="secondary"
              disabled={!formState.isDirty || !allowFormEdit}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default EditAccountSettings;
