import { Box, Divider, Drawer } from "@mui/material";
import { GridColDef, GridRowClassNameParams, GridRowParams } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Waypoint } from "react-waypoint";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import PreviewFileDialog from "../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { FileInfo } from "../../../../api/types/commonApiTypes";
import { useInboxStateContext } from "../../../../contexts/InboxStateContext";
import { usePdfPreview } from "../../../../hooks/pdfPreview";
import { useLocalization } from "../../../../hooks/useLocalization";
import { messagesSelector } from "../../../../store/state/messages/selectors";
import { Message, MessageType } from "../../../../store/state/messages/types";
import { impersonationSelector } from "../../../../store/state/user/selectors";
import Actions from "../Actions";
import NoEmailsComponent from "../noEmails/NoEmailsComponent";
import { MessageItemComponent } from "./messageItem/MessageItemComponent";

export const MessageListViewComponent = () => {
  const { tag } = useParams();

  const dispatch = useDispatch();
  const [waypoints, setWaypoints] = useState([] as string[]);
  const [previewFileData, setPreviewData] = useState<FileInfo | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { sendNotificationError } = useNotificationContext();
  const { prepareMessageForPreview } = usePdfPreview();
  const isImpersonation = useSelector(impersonationSelector);
  const messages = useSelector(messagesSelector);
  const { ui, criteria, setMessageId, updateCriteriaPreload, selection, setSelection } = useInboxStateContext();
  const locale = useLocalization();

  useEffect(() => {
    setWaypoints([]);
  }, [tag, criteria.query]);

  const preloadData = useCallback(
    (messageId: string, stalePage: number) => {
      if (stalePage !== criteria.page) {
        return;
      }

      if (ui.messagesLoading) {
        return;
      }

      if (waypoints.indexOf(messageId) !== -1) {
        return;
      }
      waypoints.push(messageId);

      const newPage = criteria.page + 1;
      updateCriteriaPreload({ page: newPage, preload: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [criteria, ui, waypoints, dispatch]
  );

  const columns: GridColDef[] = [
    {
      field: "all",
      headerName: " ",
      flex: 1,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        const message = params.row as Message;
        const isWaypoint =
          ui.total &&
          ui.total > criteria.size &&
          waypoints.indexOf(message.id) === -1 &&
          params.api.getRowIndexRelativeToVisibleRows(params.id) === messages.length - criteria.size / 2;
        if (isWaypoint) {
          const currentPage = criteria.page;
          return (
            <>
              <MessageItemComponent message={message} />
              <Waypoint
                onEnter={() => {
                  preloadData(message.id, currentPage);
                }}
              ></Waypoint>
            </>
          );
        }
        return <MessageItemComponent message={message} />;
      },
    },
  ];

  const onNavigateToMessage = useCallback(
    (params: GridRowParams) => {
      const message = params.row as Message;
      if (message.type === MessageType.Letter) {
        setMessageId(message.id);
      } else {
        setIsLoading(true);
        prepareMessageForPreview(message, !isImpersonation)
          .then((downloadData) => {
            setPreviewData(downloadData);
          })
          .catch(() => {
            sendNotificationError(locale.inbox.preview_file_error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [setMessageId, prepareMessageForPreview, sendNotificationError, isImpersonation, locale.inbox.preview_file_error]
  );

  if (messages.length === 0 && !ui.messagesLoading) {
    return <NoEmailsComponent />;
  }

  const getRowClassName = (params: GridRowClassNameParams) => {
    const message = params.row as Message;
    if (message?.isImportant === true) {
      return "important";
    }
    return "";
  };

  const isDrawerOpen = selection.length > 0;

  return (
    <Box width="100%" display="flex" flexDirection="column" flex={1}>
      <Divider flexItem />
      <DataGrid
        sx={{
          mb: isDrawerOpen ? 9 : 1,
          border: "none",
          ".MuiDataGrid-row": {
            borderLeft: "4px solid white",
            "&.important": {
              borderLeftColor: "#FFB400",
            },
            "&:hover": {
              cursor: "pointer",
            },
            ".MuiDataGrid-cell": {
              p: 0,
              borderColor: "#DDDFE2",
              outline: "none",
              lineHeight: "inherit",
            },
            ".MuiDataGrid-cellCheckbox": {
              svg: {
                fontSize: "1.25rem",
              },
            },
          },
        }}
        rows={messages}
        columns={columns}
        loading={ui.messagesLoading || isLoading}
        onRowSelectionModelChange={setSelection}
        rowSelectionModel={selection}
        checkboxSelection
        columnHeaderHeight={0}
        rowHeight={100}
        getRowClassName={getRowClassName}
        onRowClick={(e) => {
          if (!isLoading) {
            onNavigateToMessage(e);
          }
        }}
      />
      {previewFileData && (
        <PreviewFileDialog
          onClose={() => setPreviewData(undefined)}
          url={previewFileData.downloadUrl}
          fileName={previewFileData.fileName}
          documentViewSessionId={previewFileData.documentViewSessionId}
        />
      )}
      <Drawer
        open={isDrawerOpen}
        anchor="bottom"
        sx={{ height: "70px" }}
        hideBackdrop
        variant="temporary"
        slotProps={{
          paper: {
            sx: {
              height: "inherit",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            },
          },
        }}
      >
        <Box width="100%" display="flex" alignItems="center" justifyContent="center" flex={1}>
          <Actions setSelection={setSelection} selection={selection} compact></Actions>
        </Box>
      </Drawer>
    </Box>
  );
};
