import axios from "axios";
import qs from "qs";
import { createApiResponse, createErrorApiResponse } from "../../shared/api/axiosHelper";
import { WorkflowType } from "../../shared/api/changeRequestTypes";
import { clientCodeHeader, getClientCode } from "../../shared/api/clientApiContext";
import {
  CreateMultipleObjectMetricExtensionsRequest,
  ObjectMetricExtension,
  SaveSubmissionLayoutBlockInputRequest,
  SearchObjectMetricExtensionsRequest,
} from "../../shared/api/dataCollectionTypes";
import { ApiResponse, FileDownloadInfo } from "../../shared/api/types";
import { getBackendBaseUrl } from "../../shared/variables";
import { InboxStructureTree } from "../store/state/inboxStructure/types";
import {
  FilterConfiguration,
  Message,
  SearchCriteria,
  TranslateIdsRequest,
  TranslateIdsResponse,
} from "../store/state/messages/types";
import { Identity, UpdateIdentityRequest, UserPermissionsResponse } from "../store/state/user/types";
import {
  AssignBankAccountsRequest,
  CreateInvestorBankAccountRequest,
  InvestorBankAccountsResponse,
} from "./types/bankAccountTypes";
import { CancelInvestorChangeRequest, ChangeRequestDetails } from "./types/changeRequestsTypes";
import { FileInfo, Fund } from "./types/commonApiTypes";
import {
  BalanceDataResponse,
  BalanceInitialData,
  CashFlowEntry,
  CashFlowRequest,
  GetBalanceRequest,
} from "./types/dashboardTypes";
import {
  DataCollectionSubmissionDetails,
  DataCollectionSubmissionInfo,
  ImportSubmissionBlockInputResponse,
  SearchDataCollectionRequest,
} from "./types/dataCollectionTypes";
import { Fundraising } from "./types/fundraisingTypes";
import { ImpersonationOptions } from "./types/impersonationTypes";
import { InvestorDetails, UpdateInvestorDetailsRequest } from "./types/investorDetailsTypes";
import { PortfolioReportRequest, TabularReport } from "./types/portfolioTypes";
import { AccessRolesAndCategories, ClientDictionaries, FundInvestor } from "./types/settingsTypes";

const baseUri = getBackendBaseUrl("ir-portal");

export const api = {
  user: {
    loadPermissions: async () => {
      const { data } = await axios.get<ApiResponse<UserPermissionsResponse>>(`${baseUri}/User/permissions`);
      return data;
    },
    loadIdentity: async (largeAvatar = false) => {
      const { data } = await axios.get<ApiResponse<Identity>>(`${baseUri}/Identity`, {
        params: {
          loadLargeAvatarImage: largeAvatar,
        },
      });
      return data;
    },
    updateIdentity: async (payload: UpdateIdentityRequest) => {
      const { data } = await axios.patch<ApiResponse<Identity>>(`${baseUri}/Identity`, payload, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    processAvatar: async (avatarImage: string) => {
      const { data } = await axios.post<ApiResponse<string>>(
        `${baseUri}/Identity/ProcessAvatar`,
        {
          avatarImage,
        },
        { headers: clientCodeHeader() }
      );
      return data;
    },
  },
  dictionaries: {
    getDictionaries: async () => {
      const { data } = await axios.get<ApiResponse<ClientDictionaries>>(`${baseUri}/Dictionaries`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
  },
  settings: {
    getFundInvestors: async () => {
      const { data } = await axios.get<ApiResponse<FundInvestor[]>>(`${baseUri}/SettingsManagement/LP/FundInvestors`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    getAccessRolesAndCategories: async () => {
      const { data } = await axios.get<ApiResponse<AccessRolesAndCategories>>(
        `${baseUri}/SettingsManagement/LP/AccessRolesAndCategories`,
        { headers: clientCodeHeader() }
      );
      return data;
    },
  },
  investorDetails: {
    getInvestorDetails: async (investorId: string) => {
      const { data } = await axios.get<ApiResponse<InvestorDetails>>(
        `${baseUri}/InvestorDetails/LP/Investors/${investorId}/details`,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    updateInvestorDetails: async (investorId: string, request: UpdateInvestorDetailsRequest) => {
      const { data } = await axios.patch<ApiResponse<ChangeRequestDetails>>(
        `${baseUri}/InvestorDetails/LP/Investors/${investorId}/details`,
        request,
        { headers: clientCodeHeader() }
      );
      return data;
    },
  },
  bankAccounts: {
    getBankAccounts: async (investorId: string) => {
      const { data } = await axios.get<ApiResponse<InvestorBankAccountsResponse>>(
        `${baseUri}/LP/Investors/${investorId}/bank-accounts`,
        {
          headers: clientCodeHeader(),
        }
      );
      return data;
    },
    createBankAccount: async (investorId: string, payload: CreateInvestorBankAccountRequest) => {
      const { data } = await axios.put<ApiResponse<ChangeRequestDetails>>(
        `${baseUri}/LP/Investors/${investorId}/bank-accounts`,
        payload,
        {
          headers: clientCodeHeader(),
        }
      );
      return data;
    },
    assignBankAccountsToFundInvestors: async (payload: AssignBankAccountsRequest) => {
      const { data } = await axios.post<ApiResponse<ChangeRequestDetails>>(
        `${baseUri}/LP/Investors/bank-account-assignments`,
        payload,
        {
          headers: clientCodeHeader(),
        }
      );
      return data;
    },
  },
  changeRequests: {
    getPendingChangeRequests: async (investorId: string, workflowType: WorkflowType) => {
      const { data } = await axios.get<ApiResponse<{ changeRequestDetails: ChangeRequestDetails[] }>>(
        `${baseUri}/LP/Investors/${investorId}/pending-change-request`,
        {
          headers: clientCodeHeader(),
          params: { workflowType },
        }
      );
      return data;
    },
    cancelChangeRequest: async (investorId: string, payload: CancelInvestorChangeRequest) => {
      const { data } = await axios.post<ApiResponse<void>>(
        `${baseUri}/LP/Investors/${investorId}/change-requests/cancel`,
        payload,
        {
          headers: clientCodeHeader(),
        }
      );
      return data;
    },
  },
  impersonation: {
    getImpersonationOptions: async () => {
      const { data } = await axios.get<ApiResponse<ImpersonationOptions>>(`${baseUri}/Impersonation/options`);
      return data;
    },
  },
  messages: {
    search: async (criteria: Partial<SearchCriteria>) => {
      const { data } = await axios.post<ApiResponse<{ items: Message[]; total: number }>>(
        `${baseUri}/Messages/Search`,
        criteria,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    getDetails: async (id: string) => {
      const { data } = await axios.get<ApiResponse<Message>>(`${baseUri}/Messages/${id}`, {
        params: { includeContent: true },
        headers: clientCodeHeader(),
      });
      return data;
    },
    markAsRead: async (id: string) => {
      const { data } = await axios.patch<ApiResponse<Message>>(
        `${baseUri}/Messages/${id}`,
        {
          markAsRead: true,
        },
        { headers: clientCodeHeader() }
      );
      return data;
    },
    markManyAsRead: async (ids: string[], markAsRead: boolean) => {
      const { data } = await axios.patch<
        ApiResponse<{ updates: { messageId: string; markAsRead: boolean; markAsImportant: boolean }[] }>
      >(
        `${baseUri}/Messages/`,
        {
          messageIds: ids,
          markAsRead,
        },
        { headers: clientCodeHeader() }
      );
      return data;
    },
    markManyAsImportant: async (ids: string[], markAsImportant: boolean) => {
      const { data } = await axios.patch<
        ApiResponse<{ updates: { messageId: string; markAsRead: boolean; markAsImportant: boolean }[] }>
      >(
        `${baseUri}/Messages/`,
        {
          messageIds: ids,
          markAsImportant,
        },
        { headers: clientCodeHeader() }
      );
      return data;
    },
    loadFilterConfiguration: async () => {
      const { data } = await axios.get<ApiResponse<FilterConfiguration>>(`${baseUri}/Messages/search/params`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    translateIds: async (payload: TranslateIdsRequest) => {
      const { data } = await axios.post<ApiResponse<TranslateIdsResponse>>(
        `${baseUri}/Messages/translate-ids`,
        payload,
        {
          headers: clientCodeHeader(),
        }
      );
      return data;
    },
  },
  attachments: {
    getUrl: async (messageId: string, attachmentId: string) => {
      const { data } = await axios.get<ApiResponse<FileInfo>>(
        `${baseUri}/Messages/${messageId}/attachments/${attachmentId}`,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    getUrlForAll: async (messageIds: string[]): Promise<string | undefined> => {
      const { data } = await axios.post<ApiResponse<{ token: string }>>(
        `${baseUri}/attachments/download`,
        {
          messageIds,
        },
        { headers: clientCodeHeader() }
      );
      return data?.success ? `${baseUri}/attachments/download?downloadToken=${data.data.token}` : undefined;
    },
  },
  inboxStructure: {
    load: async () => {
      const { data } = await axios.get<ApiResponse<InboxStructureTree>>(`${baseUri}/Inbox/Structure`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
  },
  inboxUpdatesSubscription: {
    getToken: async () => {
      const { data } = await axios.get<ApiResponse<string>>(`${baseUri}/Inbox/updates-subscription-token`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
  },
  dashboard: {
    getBalance: async (request: GetBalanceRequest) => {
      const { data } = await axios.post<ApiResponse<BalanceDataResponse>>(`${baseUri}/Dashboard/balance`, request, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    getCashFlows: async (requestData: CashFlowRequest) => {
      const { data } = await axios.post<ApiResponse<CashFlowEntry[]>>(`${baseUri}/Dashboard/capCalls`, requestData, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    getInitialData: async () => {
      const { data } = await axios.get<ApiResponse<BalanceInitialData>>(`${baseUri}/Dashboard/balance/initialData`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
  },
  fundraising: {
    getFundraising: async (id: string) => {
      const { data } = await axios.get<ApiResponse<Fundraising>>(`${baseUri}/Fundraisings/${id}`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    getFundraisingDocumentDownloadInfo: async (fundraisingId: string, documentId: string) => {
      const { data } = await axios.get<ApiResponse<FileInfo>>(
        `${baseUri}/Fundraisings/${fundraisingId}/documents/${documentId}`,
        { headers: clientCodeHeader() }
      );

      return data;
    },
    getDownloadUrlForAllFundraisingDocuments: async (fundraisingId: string): Promise<ApiResponse<string>> => {
      const { data } = await axios.post<ApiResponse<{ token: string }>>(
        `${baseUri}/Fundraisings/${fundraisingId}/documents/download`,
        null,
        { headers: clientCodeHeader() }
      );

      const downloadToken = data.data?.token;
      return downloadToken
        ? createApiResponse(
            `${baseUri}/Fundraisings/documents/download?${qs.stringify({ downloadToken, clientCode: getClientCode() })}`
          )
        : createErrorApiResponse(data.error);
    },
  },
  portfolio: {
    getFunds: async () => {
      const { data } = await axios.get<ApiResponse<Fund[]>>(`${baseUri}/Portfolio/funds`, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    runReport: async (payload: PortfolioReportRequest) => {
      const { data } = await axios.post<ApiResponse<TabularReport>>(`${baseUri}/Portfolio/report`, payload, {
        headers: clientCodeHeader(),
      });

      return data;
    },
  },
  dataCollection: {
    searchDataSubmissions: async (payload: SearchDataCollectionRequest) => {
      const { data } = await axios.post<ApiResponse<DataCollectionSubmissionInfo[]>>(
        `${baseUri}/DataSubmissions/search`,
        payload,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    getDataSubmissionDetails: async (id: string) => {
      const { data } = await axios.get<ApiResponse<DataCollectionSubmissionDetails>>(
        `${baseUri}/DataSubmissions/${id}`,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    saveDataSubmissionLayoutBlockInput: async (
      submissionId: string,
      blockId: string,
      payload: SaveSubmissionLayoutBlockInputRequest
    ) => {
      const { data } = await axios.put<ApiResponse<DataCollectionSubmissionDetails>>(
        `${baseUri}/DataSubmissions/${submissionId}/blocks/${blockId}`,
        payload,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    submitDataSubmission: async (id: string) => {
      const { data } = await axios.post<ApiResponse<null>>(`${baseUri}/DataSubmissions/${id}/submit`, null, {
        headers: clientCodeHeader(),
      });
      return data;
    },
    exportSubmissionBlockInputToExcel: async (submissionId: string, blockId: string) => {
      const { data } = await axios.post<ApiResponse<FileDownloadInfo>>(
        `${baseUri}/DataSubmissions/${submissionId}/blocks/${blockId}/excel-export`,
        null,
        { headers: clientCodeHeader() }
      );
      return data;
    },
    importSubmissionBlockInputFromExcel: async (submissionId: string, blockId: string, file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      const { data } = await axios.post<ApiResponse<ImportSubmissionBlockInputResponse>>(
        `${baseUri}/DataSubmissions/${submissionId}/blocks/${blockId}/excel-import`,
        formData,
        {
          headers: {
            ...clientCodeHeader(),
            "Content-Type": "multipart/form-data; boundary=fileContent",
          },
        }
      );
      return data;
    },
    searchObjectMetricExtensions: async (payload: SearchObjectMetricExtensionsRequest) => {
      const { data } = await axios.post<ApiResponse<ObjectMetricExtension[]>>(
        `${baseUri}/ObjectMetricExtensions/search`,
        payload,
        {
          headers: clientCodeHeader(),
        }
      );
      return data;
    },
    createObjectMetricExtensions: async (payload: CreateMultipleObjectMetricExtensionsRequest) => {
      const { data } = await axios.post<ApiResponse<ObjectMetricExtension[]>>(
        `${baseUri}/ObjectMetricExtensions/create-multiple`,
        payload,
        { headers: clientCodeHeader() }
      );
      return data;
    },
  },
};
