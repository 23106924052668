import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";
import { Button } from "@mui/material";
import { GridRowId, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { markAsImportantUpdatingSelector, messagesSelector } from "../../../../../store/state/messages/selectors";
import { markAsImportant } from "../../../../../store/state/messages/thunks";
import { impersonationSelector } from "../../../../../store/state/user/selectors";
import { AppDispatch } from "../../../../../store/store";

interface Props {
  compact?: boolean;
  selection: GridRowSelectionModel;
}

export const MarkAsImportantAction = (props: Props) => {
  const { compact, selection } = props;

  const dispatch: AppDispatch = useDispatch();

  const messages = useSelector(messagesSelector);
  const markAsImportantUpdating = useSelector(markAsImportantUpdatingSelector);
  const isImpersonation = useSelector(impersonationSelector);

  const anyNotImportantMessagesInSelected = useMemo(() => {
    return !!messages.find((msg) => selection.some((id: GridRowId) => id === msg.id && !msg.isImportant));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, selection, markAsImportantUpdating]);

  const changeSelectedMessagesImportance = useCallback(
    (isImportant: boolean) => {
      dispatch(
        markAsImportant(
          selection.map((id) => id as string),
          isImportant
        )
      );
    },
    [dispatch, selection]
  );

  const allowMarkAsImportantAction = useMemo(() => {
    return selection && selection.length > 0 && anyNotImportantMessagesInSelected;
  }, [selection, anyNotImportantMessagesInSelected]);

  return (
    <>
      {allowMarkAsImportantAction && (
        <Button
          disabled={isImpersonation}
          startIcon={<LabelImportantOutlinedIcon sx={{ color: "#FFB400" }} />}
          loadingPosition="start"
          loading={markAsImportantUpdating}
          onClick={() => changeSelectedMessagesImportance(true)}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            flexDirection: compact === true ? "column" : "inherit",
          })}
        >
          Important
        </Button>
      )}
    </>
  );
};

export default MarkAsImportantAction;
